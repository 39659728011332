define("discourse/plugins/discourse-epic-games/discourse/components/user-profile-background-image", ["exports", "@ember/helper", "@ember/render-modifiers/modifiers/did-insert", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _didInsert, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BACKGROUND_ID = "unreal-user-profile-background-img";
  const decorateParentElement = backgroundUrl1 => {
    if (backgroundUrl1) {
      const parentElement1 = document.getElementById(BACKGROUND_ID).parentElement;
      parentElement1.style.backgroundImage = `url(${backgroundUrl1})`;
    }
  };
  const UserProfileBackgroundImage = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      id={{BACKGROUND_ID}}
      {{didInsert (fn decorateParentElement @user.unreal_profile_background_url)}}
    ></div>
  
  */
  {
    "id": "2cVi9RUl",
    "block": "[[[1,\"\\n  \"],[11,0],[16,1,[32,0]],[4,[32,1],[[28,[32,2],[[32,3],[30,1,[\"unreal_profile_background_url\"]]],null]],null],[12],[13],[1,\"\\n\"]],[\"@user\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/user-profile-background-image.js",
    "scope": () => [BACKGROUND_ID, _didInsert.default, _helper.fn, decorateParentElement],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = UserProfileBackgroundImage;
});