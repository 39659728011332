define("discourse/plugins/discourse-epic-games/discourse/components/unreal-sidebar", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/utils", "discourse/lib/cookie", "discourse-common/lib/avatar-utils", "discourse-common/lib/get-url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _utils, _cookie, _avatarUtils, _getUrl, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MOBILE_SHOW = _exports.MOBILE_HIDE = void 0;
  const MOBILE_SHOW = _exports.MOBILE_SHOW = "epic-edc-sidebar-on-mobile-show";
  const MOBILE_HIDE = _exports.MOBILE_HIDE = "epic-edc-sidebar-on-mobile-hide";
  class UnrealSidebar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "unrealSidebar", [_service.service]))();
    #unrealSidebar = (() => (dt7948.i(this, "unrealSidebar"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "discussions_posts_count", [_tracking.tracked], function () {
      return 0;
    }))();
    #discussions_posts_count = (() => (dt7948.i(this, "discussions_posts_count"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "discussions_answers_count", [_tracking.tracked], function () {
      return 0;
    }))();
    #discussions_answers_count = (() => (dt7948.i(this, "discussions_answers_count"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "readyToRenderSidebar", [_tracking.tracked], function () {
      return false;
    }))();
    #readyToRenderSidebar = (() => (dt7948.i(this, "readyToRenderSidebar"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "mobileShown", [_tracking.tracked], function () {
      return false;
    }))();
    #mobileShown = (() => (dt7948.i(this, "mobileShown"), void 0))();
    constructor() {
      super(...arguments);
      // listen for tap that brings the sidebar out of its minimal, low-height state
      // and back into it.
      window.document.addEventListener(MOBILE_SHOW, () => {
        this.mobileShown = true;
      });
      window.document.addEventListener(MOBILE_HIDE, () => {
        this.mobileShown = false;
      });
      // don't show sidebar until we've called currentUser.findDetails
      // this loads backgroundUrl as well as user stats.
      if (!this.currentUser) {
        this.readyToRenderSidebar = true;
      } else {
        this.currentUser.findDetails().then(() => {
          this.readyToRenderSidebar = true;
          this.discussions_posts_count = this.currentUser.get("post_count") || 0;
          this.discussions_answers_count = this.currentUser.get("answer_count") || 0;
        });
      }
    }
    get locale() {
      // these locales are coming from top nav drop down,
      // which uses slightly different strings than side nav
      const map1 = {
        fr: "fr-FR",
        en: "en-US",
        de: "de-DE",
        es: "es-ES",
        ja: "ja-JP",
        ko: "ko-KR"
      };
      const _locale1 = (0, _cookie.default)("locale");
      return map1[_locale1] || _locale1;
    }
    get categories() {
      return this.unrealSidebar.categories;
    }
    get applicationFamily() {
      return this.unrealSidebar.globalFilter;
    }
    get categoryLinks() {
      const capturedThis1 = this;
      function transformCategory1(category1) {
        const transformedCategory1 = {};
        transformedCategory1.title = category1.name;
        transformedCategory1.pathname = `/tags/c/${category1.slug}/${category1.id}/${capturedThis1.applicationFamily}`;
        transformedCategory1.count = category1.topic_count;
        if (category1.subcategories) {
          transformedCategory1.subItems = transformCategories1(category1.subcategories); //recurse
        }
        return transformedCategory1;
      }
      function transformCategories1(categories1) {
        return categories1.map(category1 => transformCategory1(category1));
      }
      const parentCategories1 = this.categories?.filter(cat1 => !cat1.parent_category_id);
      const categories1 = transformCategories1(parentCategories1);
      return JSON.stringify(categories1);
    }
    get userBackgroundImageUrl() {
      if (!this.currentUser) {
        return null;
      }
      const url1 = this.currentUser.card_background_upload_url;
      const bg1 = (0, _utils.isEmpty)(url1) ? "" : (0, _getUrl.getURLWithCDN)(url1);
      return bg1;
    }
    get userBadges() {
      if (!this.currentUser) {
        return "";
      }
      const badges1 = (this.currentUser.badges || []).map(_1 => _1.name.toLowerCase());
      return JSON.stringify(badges1);
    }
    get userAvatarUrl() {
      if (this.currentUser) {
        const avatarTemplate1 = this.currentUser.get("avatar_template");
        return (0, _avatarUtils.avatarUrl)(avatarTemplate1, "huge", {});
      }
    }
    get totalUnreadNotifications() {
      if (!this.currentUser) {
        return;
      }
      let total1 = 0;
      Object.values(this.currentUser.get("grouped_unread_notifications")).forEach(value1 => {
        const parsed1 = parseInt(value1, 10);
        if (isNaN(parsed1)) {
          return;
        }
        total1 += value1;
      });
      return total1 > 50 ? 50 : total1;
    }
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="above-site-header-outlet sidebar">
          <div class="unreal-sidebar">
            {{#if this.readyToRenderSidebar}}
              {{! eslint-disable-next-line no-undef }}
              <edc-sidebar
                app-context="discourse"
                user-hash-id={{this.currentUser.id}}
                notifications-count={{this.totalUnreadNotifications}}
                user-avatar-url={{this.userAvatarUrl}}
                user-username={{this.currentUser.username}}
                user-name={{this.currentUser.name}}
                locale={{this.locale}}
                application-family={{this.applicationFamily}}
                user-background-image-url={{this.userBackgroundImageUrl}}
                can-access-manage="true"
                category-links={{this.categoryLinks}}
              />
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "aaLfCyda",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"above-site-header-outlet sidebar\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"unreal-sidebar\"],[12],[1,\"\\n\"],[41,[30,0,[\"readyToRenderSidebar\"]],[[[1,\"          \"],[10,\"edc-sidebar\"],[14,\"app-context\",\"discourse\"],[15,\"user-hash-id\",[30,0,[\"currentUser\",\"id\"]]],[15,\"notifications-count\",[30,0,[\"totalUnreadNotifications\"]]],[15,\"user-avatar-url\",[30,0,[\"userAvatarUrl\"]]],[15,\"user-username\",[30,0,[\"currentUser\",\"username\"]]],[15,\"user-name\",[30,0,[\"currentUser\",\"name\"]]],[15,\"locale\",[30,0,[\"locale\"]]],[15,\"application-family\",[30,0,[\"applicationFamily\"]]],[15,\"user-background-image-url\",[30,0,[\"userBackgroundImageUrl\"]]],[14,\"can-access-manage\",\"true\"],[15,\"category-links\",[30,0,[\"categoryLinks\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/unreal-sidebar.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UnrealSidebar;
});