define("discourse/plugins/discourse-epic-games/discourse/templates/components/sub-category-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.isHidden}}
    {{category-link this.category}}
  {{/unless}}
  */
  {
    "id": "c4coaWrq",
    "block": "[[[41,[51,[30,0,[\"isHidden\"]]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"category\"]]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"category-link\"]]",
    "moduleName": "discourse/plugins/discourse-epic-games/discourse/templates/components/sub-category-row.hbs",
    "isStrictMode": false
  });
});