define("discourse/plugins/discourse-epic-games/discourse/initializers/search-init", ["exports", "@ember/object", "discourse/components/search-menu", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _object, _searchMenu, _pluginApi, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-search-init",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.forceDropdownForMenuPanels("search-menu-panel");
        api.modifyClass("component:search-menu", dt7948.p({
          pluginId: _constants.PLUGIN_ID,
          close() {
            // this is copied from core due to https://dev.discourse.org/t/modifyclass-action-and-this-super-headaches/116604
            // we cannot call `this._super(...arguments)` to call the original close action
            if (this.args?.onClose) {
              return this.args.onClose();
            }

            // We want to blur the search input when in stand-alone mode
            // so that when we focus on the search input again, the menu panel pops up
            document.getElementById(_searchMenu.SEARCH_INPUT_ID)?.blur();
            this.menuPanelOpen = false;

            // this is our custom logic to close the epic header
            document.querySelector(".unreal-header-nav-wrap").firstElementChild.closeAll();
          }
        }, [["method", "close", [_object.action]]]));
      });
    }
  };
});