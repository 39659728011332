define("discourse/plugins/discourse-epic-games/discourse/initializers/header-breadcrumbs", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/components/header-breadcrumbs", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _pluginApi, _headerBreadcrumbs, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-header-breadcrumbs",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.epic_enable_header_breadcrumbs) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.26.0", api => {
        api.renderAfterWrapperOutlet("home-logo", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                <div class="forum-hero-banner">
                  <HeaderBreadcrumbs />
                </div>
              
        */
        {
          "id": "buZuFYF3",
          "block": "[[[1,\"\\n        \"],[10,0],[14,0,\"forum-hero-banner\"],[12],[1,\"\\n          \"],[8,[32,0],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[],false,[]]",
          "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/header-breadcrumbs.js",
          "scope": () => [_headerBreadcrumbs.default],
          "isStrictMode": true
        }), (0, _templateOnly.default)()));
      });
    }
  };
});