define("discourse/plugins/discourse-epic-games/discourse/components/consent-pending-modal", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/url", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _template2, _dButton, _dModal, _url, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ConsentPendingModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get consentUrl() {
      return `${this.siteSettings.epic_external_digital_consent_url}${this.currentUser.id}`;
    }
    redirectToEpicConsent() {
      this.args.closeModal();
      _url.default.routeTo(this.consentUrl);
    }
    static #_3 = (() => dt7948.n(this.prototype, "redirectToEpicConsent", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @closeModal={{@closeModal}}
          class="consent-pending-modal"
          @title={{i18n "discourse_epic_games.consent_pending_modal.title"}}
        >
          <:body>
            {{htmlSafe (i18n "discourse_epic_games.consent_pending_modal.body")}}
          </:body>
          <:footer>
            <DButton
              @action={{this.redirectToEpicConsent}}
              id="consent-pending-modal-update-consent"
              @label="discourse_epic_games.consent_pending_modal.update_consent"
              class="btn-primary"
            />
            <DButton
              @label="discourse_epic_games.consent_pending_modal.cancel"
              @action={{@closeModal}}
            />
          </:footer>
        </DModal>
      
    */
    {
      "id": "WdhO5NPZ",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"consent-pending-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[32,1],[\"discourse_epic_games.consent_pending_modal.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[1,[28,[32,2],[[28,[32,1],[\"discourse_epic_games.consent_pending_modal.body\"],null]],null]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,3],[[24,1,\"consent-pending-modal-update-consent\"],[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"redirectToEpicConsent\"]],\"discourse_epic_games.consent_pending_modal.update_consent\"]],null],[1,\"\\n        \"],[8,[32,3],null,[[\"@label\",\"@action\"],[\"discourse_epic_games.consent_pending_modal.cancel\",[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/consent-pending-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _template2.htmlSafe, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ConsentPendingModal;
});