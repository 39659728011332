define("discourse/plugins/discourse-epic-games/discourse/lib/present-digital-consent-modal", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/consent-pending-modal", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _consentPendingModal, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = displayConsentPendingModal;
  _exports.hasDigitalConsentPendingGroup = hasDigitalConsentPendingGroup;
  function displayConsentPendingModal(currentUser, modal) {
    // return the modal if the user is in the digital_consent_pending group
    if (hasDigitalConsentPendingGroup(currentUser)) {
      return modal.show(_consentPendingModal.default);
    }

    // otherwise, return false and continue
    return false;
  }
  function hasDigitalConsentPendingGroup(currentUser) {
    if (!currentUser || !currentUser.groups) {
      return false;
    }
    return currentUser.groups.some(group => group.name === _constants.DIGITAL_CONSENT_PENDING_GROUP);
  }
});