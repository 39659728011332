define("discourse/plugins/discourse-epic-games/discourse/lib/open-composer", ["exports", "discourse/models/composer", "discourse-common/lib/get-owner", "discourse/plugins/discourse-epic-games/discourse/lib/inject-composer-filter-tag", "discourse/plugins/discourse-epic-games/discourse/lib/present-digital-consent-modal"], function (_exports, _composer, _getOwner, _injectComposerFilterTag, _presentDigitalConsentModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.openComposer = openComposer;
  function openComposer() {
    let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const composerController = (0, _getOwner.getOwnerWithFallback)(this).lookup("controller:composer");
    const site = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site");
    const modal = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:modal");
    const currentUser = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:current-user");
    const siteSettings = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:site-settings");
    let tags = composerController.model?.tags || [];
    const filter = opts.filter || site.globalFilter;
    if (siteSettings.epic_digital_consent_cta_enabled && (0, _presentDigitalConsentModal.default)(currentUser, modal)) {
      return;
    }
    (0, _injectComposerFilterTag.injectComposerFilterTag)(filter, opts.deselect, tags);
    if (opts.selectedAction === "new_question") {
      tags.push("question");
    }
    const skipFormTemplate = opts.hasFormTemplate && opts.selectedAction !== "new_issue_report";
    if (opts.editing) {
      composerController.model.set("tags", tags);
    } else {
      composerController.open({
        action: _composer.default.CREATE_TOPIC,
        draftKey: _composer.default.DRAFT,
        categoryId: opts.categoryId,
        tags,
        skipFormTemplate
      });
      composerController.set("formTemplateInitialValues", {
        refid: opts.refid
      });
    }
  }
});