define("discourse/plugins/discourse-epic-games/discourse/templates/components/subcategories-with-featured-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! This is a full template override. Not an ideal solution, but it is core functionality to Epic. }}
  {{#each this.categories as |category|}}
    <table class="category-list subcategory-list with-topics">
      <thead>
        <tr>
          <th class="parent-category">
            <CategoryTitleLink @category={{category}} />
            <span class="stat">
              {{! Updated stat is posts per week instead of topics per week }}
              {{category.posts_week}}
              <span class="stat-period">
                {{i18n "discourse_epic_games.topic_list.per_week"}}
              </span>
            </span>
          </th>
          <th class="topics">{{i18n "categories.topics"}}</th>
          <th class="replies">{{i18n "replies"}}</th>
          <th class="latest">{{i18n "categories.latest"}}</th>
        </tr>
      </thead>
      <tbody aria-labelledby="categories-only-category">
        {{! subcategory_list from global filters does not include children (unless specifically configured) }}
        {{#each category.subcategories as |subCategory|}}
          <ParentCategoryRow @category={{subCategory}} @showTopics={{true}} />
        {{else}}
          {{! No subcategories... show nothing (core shows parent) }}
        {{/each}}
      </tbody>
    </table>
  {{/each}}
  */
  {
    "id": "kA3ug2QV",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"  \"],[10,\"table\"],[14,0,\"category-list subcategory-list with-topics\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,0,\"parent-category\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n          \"],[10,1],[14,0,\"stat\"],[12],[1,\"\\n\"],[1,\"            \"],[1,[30,1,[\"posts_week\"]]],[1,\"\\n            \"],[10,1],[14,0,\"stat-period\"],[12],[1,\"\\n              \"],[1,[28,[35,3],[\"discourse_epic_games.topic_list.per_week\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"topics\"],[12],[1,[28,[35,3],[\"categories.topics\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"replies\"],[12],[1,[28,[35,3],[\"replies\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"latest\"],[12],[1,[28,[35,3],[\"categories.latest\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[14,\"aria-labelledby\",\"categories-only-category\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"subcategories\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],null,[[\"@category\",\"@showTopics\"],[[30,2],true]],null],[1,\"\\n\"]],[2]],[[],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"category\",\"subCategory\"],false,[\"each\",\"-track-array\",\"category-title-link\",\"i18n\",\"parent-category-row\"]]",
    "moduleName": "discourse/plugins/discourse-epic-games/discourse/templates/components/subcategories-with-featured-topics.hbs",
    "isStrictMode": false
  });
});